<template>
    <div class="vx-card p-6 function-error">
        <H3 class="color">Không thể sử dụng chức năng này.</H3>
        <vs-button class="ml-5" @click="toUserPackage">Nâng cấp gói dịch vụ</vs-button>
    </div>
</template>

<script>

export default {
    components: {
    },
    data() {
        return {
        }
    },
    created() {
    },
    watch: {

    },
    methods: {
        toUserPackage(){
          this.$router.push("/user/register-organization-package");
        }
    },
    mounted() {
    },
}
</script>

<style scoped>
    .function-error {
        min-height: 20px;
        display: flex;
        justify-content: center;
        align-items: center;
        border: 1px solid green;
    }
</style>
